//mcui-megamenu-scripts

//START:Prevents an href=# from scrolling to the top of the page
$('nav a[href="#"]').click(function (e) {
  e.preventDefault();
});
//END

// START: This section is for IE Array.from
//It implements array.from
if (!Array.from) {
  Array.from = (function () {
      var toStr = Object.prototype.toString;
      var isCallable = function (fn) {
          return typeof fn === 'function' || toStr.call(fn) === '[object Function]';
      };
      var toInteger = function (value) {
          var number = Number(value);
          if (isNaN(number)) { return 0; }
          if (number === 0 || !isFinite(number)) { return number; }
          return (number > 0 ? 1 : -1) * Math.floor(Math.abs(number));
      };
      var maxSafeInteger = Math.pow(2, 53) - 1;
      var toLength = function (value) {
          var len = toInteger(value);
          return Math.min(Math.max(len, 0), maxSafeInteger);
      };

      // The length property of the from method is 1.
      return function from(arrayLike/*, mapFn, thisArg */) {
          // 1. Let C be the this value.
          var C = this;

          // 2. Let items be ToObject(arrayLike).
          var items = Object(arrayLike);

          // 3. ReturnIfAbrupt(items).
          if (arrayLike == null) {
              throw new TypeError("Array.from requires an array-like object - not null or undefined");
          }

          // 4. If mapfn is undefined, then let mapping be false.
          var mapFn = arguments.length > 1 ? arguments[1] : void undefined;
          var T;
          if (typeof mapFn !== 'undefined') {
              // 5. else
              // 5. a If IsCallable(mapfn) is false, throw a TypeError exception.
              if (!isCallable(mapFn)) {
                  throw new TypeError('Array.from: when provided, the second argument must be a function');
              }

              // 5. b. If thisArg was supplied, let T be thisArg; else let T be undefined.
              if (arguments.length > 2) {
                  T = arguments[2];
              }
          }

          // 10. Let lenValue be Get(items, "length").
          // 11. Let len be ToLength(lenValue).
          var len = toLength(items.length);

          // 13. If IsConstructor(C) is true, then
          // 13. a. Let A be the result of calling the [[Construct]] internal method of C with an argument list containing the single item len.
          // 14. a. Else, Let A be ArrayCreate(len).
          var A = isCallable(C) ? Object(new C(len)) : new Array(len);

          // 16. Let k be 0.
          var k = 0;
          // 17. Repeat, while k < len… (also steps a - h)
          var kValue;
          while (k < len) {
              kValue = items[k];
              if (mapFn) {
                  A[k] = typeof T === 'undefined' ? mapFn(kValue, k) : mapFn.call(T, kValue, k);
              } else {
                  A[k] = kValue;
              }
              k += 1;
          }
          // 18. Let putStatus be Put(A, "length", len, true).
          A.length = len;
          // 20. Return A.
          return A;
      };
  }());
}
// END: This section is for IE Array.from


// START: This section is for the mcui-MegaMenu interactions
"use strict";

var links = Array.from(document.querySelectorAll('.bar .left .section-container')); // main navigation links
var navigation = document.querySelector('.navigation'); // navigation containing div
var drawerLinksContainer = Array.from(document.querySelectorAll('.menu .links-drawer'));
var drawerLinks = Array.from(document.querySelectorAll('.menu .links-drawer .links a')); // drawer links
var drawers = Array.from(document.querySelectorAll('.drawer')); // drawers
// var searchButton = document.querySelector('.search-link .search-open'); // search searchButton
// var searchButtonClose = document.querySelector('.search-link .search-close'); // search searchButton
// var searchdiv = document.querySelector('.search-link'); // search container
// var searchInput = document.querySelector('.search-link .field input'); // search input
var sectionDropdown = document.querySelector('.section-dropdown .dropdown'); // section dropdown button
var dropdownContent = document.querySelector('.section-dropdown .dropdown-content'); // dropdown content
var langContainer = document.querySelector('.lang'); //Language Container
var langButton = document.querySelector('.lang .lang-button'); //Language Button
var langContent = document.querySelector('.lang .lang-content'); //Language Button

//Mobile navigation
var mobileButton = document.querySelector('.nav-button'); // nav button for mobile
var mobileNav = document.querySelector('.bar.customer'); // mobile nav
var mobileSectCont = Array.from(document.querySelectorAll('.section-container')); //selects the open section container
var mobileMenuReturn = Array.from(document.querySelectorAll('.mobile-menu-return')); //Returns the mobile Nav to default

// opening navigation functionality
var opened = null;
var overlay = document.querySelector('.overlay');
links.forEach(function (x) {
  overlay.addEventListener('click', function () {
      this.classList.remove('show');
      links.forEach(function (v) {
          v.classList.remove('open');
      });
      opened = null;
  });
});
links.forEach(function (x) {
  var timer;
  x.querySelector('.main-link').addEventListener('click', function () {
      var _this = this;

      // var linksNodes = x.querySelectorAll('.links a');
      // var linksFirst = linksNodes[0];
      // var subLinksNodes = x.querySelectorAll('.sub-links .drawer');
      // var subLinksFirst = subLinksNodes[0];

      timer = setTimeout(function () {
          var menu = _this;

          if (!opened) {
              // no menu item is shown
              opened = menu;
              opened.parentElement.classList.toggle('open');
              document.querySelector('.overlay').classList.add('show');
              x.querySelector('.links-drawer').classList.add('opened');


              //reset the open sub-menus for Menu Aim
              // x.querySelector('.links a.selected').classList.remove('selected');
              // linksFirst.classList.add('selected');
              // x.querySelector('.sub-links .drawer.open').classList.remove('open');
              // subLinksFirst.classList.add('open');

          } else if (menu == opened) {
              // the clicked item is already showing
              menu.parentElement.classList.toggle('open');
              document.querySelector('.overlay').classList.remove('show');
              opened = null;
          } else {
              //debugger;
              // the clicked item is hiddden but another one is showing
              opened.parentElement.classList.toggle('open');
              opened = menu;
              opened.parentElement.classList.toggle('open');

              //reset the open sub-menus for Menu Aim
              // x.querySelector('.links a.selected').classList.remove('selected');
              // linksFirst.classList.add('selected');
              // x.querySelector('.sub-links .drawer.open').classList.remove('open');
              // subLinksFirst.classList.add('open');
          }
      }, 100);
  });
});


// nav scrolling functionality
// var windowWidth = window.innerWidth;
// window.addEventListener("resize", function () {
//     windowWidth = window.innerWidth;
// });
// window.addEventListener('scroll', function () {
//     if (window.scrollY > 300 && windowWidth > 992) {
//         if (!navigation.classList.contains('scrolled')) {
//             navigation.classList.add('scrolled');
//         }
//     } else {
//         if (navigation.classList.contains('scrolled')) {
//             navigation.classList.remove('scrolled');
//         }
//     }
// });

// search button click
// searchButton.addEventListener('click', function (e) {
//     e.preventDefault();
//     searchdiv.classList.add('open');
//     navigation.classList.add('search-open');
//     searchInput.focus();
// });

// searchButtonClose.addEventListener('click', function (e) {
//     e.preventDefault();
//     searchdiv.classList.remove('open');
//     navigation.classList.remove('search-open');
// });

// language button hover
langButton.addEventListener('click', function (e) {
e.preventDefault();
langContainer.classList.toggle('open');
});

// language mouseleave
langContent.addEventListener('mouseleave', function (e) {
langContainer.classList.remove('open');
});

// mobile functionality
mobileButton.addEventListener('click', function () {
  mobileNav.classList.toggle('open');
});

mobileMenuReturn.forEach(function (m) {
  m.addEventListener('click', function () {
      links.forEach(function (n) {
          if (n.classList.contains('open')) {
              n.classList.remove('open');
          }
          n.querySelector('.links-drawer').classList.remove('opened');
      });
      opened = null;
  });
});
// END: This section is for the mcui-MegaMenu interactions


// START: This section is for the mcui-menuaim or mouse intent
(function ($) {

  $.fn.menuAim = function (opts) {
      // Initialize menu-aim for all elements in jQuery collection
      this.each(function () {
          init.call(this, opts);
      });

      return this;
  };

  function init(opts) {
      var $menu = $(this),
          activeRow = $.noop,
          mouseLocs = [],
          lastDelayLoc = null,
          timeoutId = null,
          options = $.extend({
              rowSelector: "> a",
              submenuSelector: "*",
              submenuDirection: "right",
              tolerance: 100,  // bigger = more forgivey when entering submenu
              enter: $.noop,
              exit: $.noop,
              activate: $.noop,
              deactivate: $.noop,
              exitMenu: $.noop
          }, opts);

      var MOUSE_LOCS_TRACKED = 3,  // number of past mouse locations to track
          DELAY = 300;  // ms delay when user appears to be entering submenu

      /**
       * Keep track of the last few locations of the mouse.
       */
      var mousemoveDocument = function (e) {
          mouseLocs.push({ x: e.pageX, y: e.pageY });

          if (mouseLocs.length > MOUSE_LOCS_TRACKED) {
              mouseLocs.shift();
          }
      };

      /**
       * Cancel possible row activations when leaving the menu entirely
       */
      var mouseleaveMenu = function () {
          if (timeoutId) {
              clearTimeout(timeoutId);
          }

          // If exitMenu is supplied and returns true, deactivate the
          // currently active row on menu exit.
          if (options.exitMenu(this)) {
              if (activeRow) {
                  options.deactivate(activeRow);
              }

              activeRow = null;
          }
      };

      /**
       * Trigger a possible row activation whenever entering a new row.
       */
      var mouseenterRow = function () {
          if (timeoutId) {
              // Cancel any previous activation delays
              clearTimeout(timeoutId);
          }

          options.enter(this);
          possiblyActivate(this);
      },
          mouseleaveRow = function () {
              options.exit(this);
          };

      /*
       * Immediately activate a row if the user clicks on it.
       */
      var clickRow = function () {
          activate(this);
      };

      /**
       * Activate a menu row.
       */
      var activate = function (row) {
          if (row == activeRow) {
              return;
          }

          if (activeRow) {
              options.deactivate(activeRow);
          }

          options.activate(row);
          activeRow = row;
      };

      /**
       * Possibly activate a menu row. If mouse movement indicates that we
       * shouldn't activate yet because user may be trying to enter
       * a submenu's content, then delay and check again later.
       */
      var possiblyActivate = function (row) {
          var delay = activationDelay();

          if (delay) {
              timeoutId = setTimeout(function () {
                  possiblyActivate(row);
              }, delay);
          } else {
              activate(row);
          }
      };

      /**
       * Return the amount of time that should be used as a delay before the
       * currently hovered row is activated.
       *
       * Returns 0 if the activation should happen immediately. Otherwise,
       * returns the number of milliseconds that should be delayed before
       * checking again to see if the row should be activated.
       */
      var activationDelay = function () {
          if (!activeRow || !$(activeRow).is(options.submenuSelector)) {
              // If there is no other submenu row already active, then
              // go ahead and activate immediately.
              return 0;
          }

          var offset = $menu.offset(),
              upperLeft = {
                  x: offset.left,
                  y: offset.top - options.tolerance
              },
              upperRight = {
                  x: offset.left + $menu.outerWidth(),
                  y: upperLeft.y
              },
              lowerLeft = {
                  x: offset.left,
                  y: offset.top + $menu.outerHeight() + options.tolerance
              },
              lowerRight = {
                  x: offset.left + $menu.outerWidth(),
                  y: lowerLeft.y
              },
              loc = mouseLocs[mouseLocs.length - 1],
              prevLoc = mouseLocs[0];

          if (!loc) {
              return 0;
          }

          if (!prevLoc) {
              prevLoc = loc;
          }

          if (prevLoc.x < offset.left || prevLoc.x > lowerRight.x ||
              prevLoc.y < offset.top || prevLoc.y > lowerRight.y) {
              // If the previous mouse location was outside of the entire
              // menu's bounds, immediately activate.
              return 0;
          }

          if (lastDelayLoc &&
              loc.x == lastDelayLoc.x && loc.y == lastDelayLoc.y) {
              // If the mouse hasn't moved since the last time we checked
              // for activation status, immediately activate.
              return 0;
          }

          // Detect if the user is moving towards the currently activated
          // submenu.
          //
          // If the mouse is heading relatively clearly towards
          // the submenu's content, we should wait and give the user more
          // time before activating a new row. If the mouse is heading
          // elsewhere, we can immediately activate a new row.
          //
          // We detect this by calculating the slope formed between the
          // current mouse location and the upper/lower right points of
          // the menu. We do the same for the previous mouse location.
          // If the current mouse location's slopes are
          // increasing/decreasing appropriately compared to the
          // previous's, we know the user is moving toward the submenu.
          //
          // Note that since the y-axis increases as the cursor moves
          // down the screen, we are looking for the slope between the
          // cursor and the upper right corner to decrease over time, not
          // increase (somewhat counterintuitively).
          function slope(a, b) {
              return (b.y - a.y) / (b.x - a.x);
          };

          var decreasingCorner = upperRight,
              increasingCorner = lowerRight;

          // Our expectations for decreasing or increasing slope values
          // depends on which direction the submenu opens relative to the
          // main menu. By default, if the menu opens on the right, we
          // expect the slope between the cursor and the upper right
          // corner to decrease over time, as explained above. If the
          // submenu opens in a different direction, we change our slope
          // expectations.
          if (options.submenuDirection == "left") {
              decreasingCorner = lowerLeft;
              increasingCorner = upperLeft;
          } else if (options.submenuDirection == "below") {
              decreasingCorner = lowerRight;
              increasingCorner = lowerLeft;
          } else if (options.submenuDirection == "above") {
              decreasingCorner = upperLeft;
              increasingCorner = upperRight;
          }

          var decreasingSlope = slope(loc, decreasingCorner),
              increasingSlope = slope(loc, increasingCorner),
              prevDecreasingSlope = slope(prevLoc, decreasingCorner),
              prevIncreasingSlope = slope(prevLoc, increasingCorner);

          if (decreasingSlope < prevDecreasingSlope &&
              increasingSlope > prevIncreasingSlope) {
              // Mouse is moving from previous location towards the
              // currently activated submenu. Delay before activating a
              // new menu row, because user may be moving into submenu.
              lastDelayLoc = loc;
              return DELAY;
          }

          lastDelayLoc = null;
          return 0;
      };

      /**
       * Hook up initial menu events
       */
      $menu
          .mouseleave(mouseleaveMenu)
          .find(options.rowSelector)
          .mouseenter(mouseenterRow)
          .mouseleave(mouseleaveRow)
          .click(clickRow);

      $(document).mousemove(mousemoveDocument);

  };
})(jQuery);

$(".links").menuAim({
  activate: activateSubmenu,
  deactivate: deactivateSubmenu
});


function activateSubmenu(row) {
  var $parent = $(row).closest('.section-container.open').find('.main-link').data('section');
  var parentActive = $("." + $parent + ".menu");

  //search for active section to deactivate in case not initialized
  parentActive.find('.links .selected').removeClass('selected');
  parentActive.find('.sub-links .drawer.open').removeClass('open');

  var $row = $(row),
      submenuId = $row.data("drawer"),
      $submenu = $(".drawer" + "." + submenuId);
  $submenu.addClass('open'), $row.addClass("selected");
}

function deactivateSubmenu(row) {
  var $row = $(row),
      submenuId = $row.data("drawer"),
      $submenu = $(".drawer" + "." + submenuId);
  $submenu.removeClass('open'), $row.removeClass('selected');
}
// END: This section is for the mcui-menuaim or mouse intent


//Checks to see if the megamenu exists and if not it removes the margin-top.
function megamenuMargin() {
  var bodyTag = $('body');
  var megamenu = $('.MainNav nav.navigation');
  var win = $(window);

  if (megamenu.length == 0) {
      bodyTag.css('margin-top', '0');
  } else {
      if (win.width() >= 992) {
          bodyTag.css('margin-top', '70px');
      } else {
          bodyTag.css('margin-top', '50px');
      }
  }
}
megamenuMargin();

$(window).resize(function () {
  megamenuMargin();
}).resize();
